<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row class="mt-2">
            <v-col cols="12">
                <v-card class="rounded-l" outlined>
                    <v-card-title>
                        HRC Procurement
                        <v-spacer></v-spacer>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-3"
                        @click="getPullData()"
                        >
                            <v-icon>mdi-cached</v-icon>
                        </v-btn>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-3"
                        @click="dialog = true"
                        >
                            <v-icon>mdi-filter-variant</v-icon>
                        </v-btn>
                        <v-dialog
                            v-model="dialog"
                            max-width="600px"
                            persistent
                        >
                            <v-card>
                                <v-card-title class="p-1">                        
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        x-small
                                        color="#e83e8c;"
                                        @click="dialog = false"
                                    >
                                        <v-icon dark>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <v-container fluid>
                                        <v-row>
                                            <!-- <v-col class="col-12" sm="6" md="4">
                                                <h6 class="blue-lcd mb-1">Thick</h6>
                                                <v-autocomplete
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="thick"
                                                    :items="thicks"
                                                    item-value="thick"
                                                    :item-text="item => $store.getters.convertToCurrencyUs(item.thick)"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    
                                                ></v-autocomplete> 
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="4">
                                                <h6 class="blue-lcd mb-1">Width</h6>
                                                <v-autocomplete
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="width"
                                                    :items="widths"
                                                    item-value="Width"
                                                    :item-text="item => $store.getters.convertToCurrencyUs(item.Width)"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    
                                                ></v-autocomplete>
                                            </v-col> -->
                                            <v-col class="col-12" sm="12" md="12">
                                                <h6 class="blue-lcd mb-1">Year</h6>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="320px"
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            dense
                                                            v-model="year"
                                                            label="Year"
                                                            append-icon="event"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            solo
                                                            class="ma-0 pa-0 border-12"
                                                            readonly
                                                            hide-details=true
                                                            >
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        ref="picker"
                                                        v-model="date"
                                                        :max="picker"
                                                        @input="save"
                                                        reactive
                                                        no-title>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="text-center">
                                    <v-container fluid>
                                        <v-row justify="center">
                                            <v-col cols="12" xs="12" sm="3" md="3">
                                                <v-btn block class="rounded-l mr-2" color="#fff" @click="clear()">Clear</v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="3" md="3">
                                                <v-btn block class="rounded-l text-white" color="success" @click="[getPullData(), dialog = false]">Search</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card-title>
                    <v-card-text>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th
                                            rowspan="2"
                                            class="text-uppercase text-right"
                                            style="vertical-align: middle;text-align: center;"
                                        >
                                            Thick
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="text-uppercase text-right"
                                            style="vertical-align: middle;text-align: center;"
                                        >
                                            Width
                                        </th>
                                        <!-- <th
                                            rowspan="2"
                                            class="text-uppercase text-right"
                                            style="vertical-align: middle;text-align: center;"
                                        >
                                            Thick QQ
                                        </th> -->
                                        <th
                                            rowspan="2"
                                            class="text-uppercase text-right"
                                            style="vertical-align: middle;text-align: center;"
                                        >
                                            Width Actual
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="text-uppercase text-right"
                                            style="vertical-align: middle;text-align: center;"
                                        >
                                            Wgt HRC
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="text-uppercase text-right"
                                            style="vertical-align: middle;text-align: center;"
                                        >
                                            Wgt HRP
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="text-uppercase text-right"
                                            style="vertical-align: middle;text-align: center;"
                                        >
                                            Wgt HRPO
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            January
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            February
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            March
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            April
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            May
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            June
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            July
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            August
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            September
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            October
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            November
                                        </th>
                                        <th
                                            colspan="3"
                                            class="text-uppercase text-center"
                                        >
                                            December
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Ord CRC
                                        </th>
                                        <th class="text-center">
                                            Ord HRPO
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="bodyHrps">
                                    <tr v-for="(item, index) in data_fix" :key="index">
                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.thick) }}</td>
                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.width) }}</td>
                                        <!-- <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.thick_qq) }}</td> -->
                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.width_qq) }}</td>
                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.wgt_hrc) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.wgt_hrp) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.wgt_hrp) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.wgt_hrpo) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.wgt_hrpo) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.jan_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.jan_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.jan_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.jan_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.jan_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.feb_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.feb_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.feb_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.feb_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.feb_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.mar_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.mar_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.mar_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.mar_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.mar_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.apr_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.apr_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.apr_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.apr_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.apr_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.mei_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.mei_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.mei_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.mei_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.mei_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.jun_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.jun_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.jun_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.jun_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.jun_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.jul_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.jul_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.jul_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.jul_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.jul_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.agt_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.agt_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.agt_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.agt_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.agt_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.sep_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.sep_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.sep_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.sep_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.sep_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.okt_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.okt_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.okt_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.okt_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.okt_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.nov_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.nov_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.nov_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.nov_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.nov_po) }}</td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick_qq === datas[index - 1].thick_qq && item.width_qq === datas[index - 1].width_qq && item.same_thickqq_wqq == data_fix[index - 1].same_thickqq_wqq">{{ $store.getters.convertToCurrencyUs(item.des_ordCRC) }} Uyee CRC</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thickqq_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.des_ordCRC) }}
                                        </td>

                                        <td scope="col" style="text-align: right;display: none;" v-if="index > 0 && item.thick === datas[index - 1].thick && item.width_qq === datas[index - 1].width_qq && item.same_thick_wqq == data_fix[index - 1].same_thick_wqq">{{ $store.getters.convertToCurrencyUs(item.des_ordHRPO) }} Uyee</td>
                                        <td scope="col" style="text-align: right;vertical-align: middle;" v-else :rowspan="item.same_thick_wqq">
                                            {{ $store.getters.convertToCurrencyUs(item.des_ordHRPO) }}
                                        </td>

                                        <td scope="col" style="text-align: right;">{{ $store.getters.convertToCurrencyUs(item.des_po) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            year: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'HRC Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'HRC Procurement',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            headers:[
                { text: 'Thick', value: 'thick' , align:'right'},
                { text: 'Width', value: 'width' , align:'right'},
                { text: 'Thick QQ', value: 'thick_qq' , align:'right'},
                { text: 'Width QQ', value: 'width_qq' , align:'right'},
                { text: 'Weight HRC', value: 'wgt_hrc' , align:'right'},
                { text: 'Weight HRP', value: 'wgt_hrp' , align:'right'},
                { text: 'Weight HRPO', value: 'wgt_hrpo' , align:'right'},
                { text: 'jan_ordCRC', value: 'jan_ordCRC' , align:'right'},
                { text: 'jan_ordHRPO', value: 'jan_ordHRPO' , align:'right'},
                { text: 'jan_po', value: 'jan_po' , align:'right'},
                { text: 'feb_ordCRC', value: 'feb_ordCRC' , align:'right'},
                { text: 'feb_ordHRPO', value: 'feb_ordHRPO' , align:'right'},
                { text: 'feb_po', value: 'feb_po' , align:'right'},
                { text: 'mar_ordCRC', value: 'mar_ordCRC' , align:'right'},
                { text: 'mar_ordHRPO', value: 'mar_ordHRPO' , align:'right'},
                { text: 'mar_po', value: 'mar_po' , align:'right'},
                { text: 'apr_ordCRC', value: 'apr_ordCRC' , align:'right'},
                { text: 'apr_ordHRPO', value: 'apr_ordHRPO' , align:'right'},
                { text: 'apr_po', value: 'apr_po' , align:'right'},
                { text: 'mei_ordCRC', value: 'mei_ordCRC' , align:'right'},
                { text: 'mei_ordHRPO', value: 'mei_ordHRPO' , align:'right'},
                { text: 'mei_po', value: 'mei_po' , align:'right'},
                { text: 'jun_ordCRC', value: 'jun_ordCRC' , align:'right'},
                { text: 'jun_ordHRPO', value: 'jun_ordHRPO' , align:'right'},
                { text: 'jun_po', value: 'jun_po' , align:'right'},
                { text: 'jul_ordCRC', value: 'jul_ordCRC' , align:'right'},
                { text: 'jul_ordHRPO', value: 'jul_ordHRPO' , align:'right'},
                { text: 'jul_po', value: 'jul_po' , align:'right'},
                { text: 'agt_ordCRC', value: 'agt_ordCRC' , align:'right'},
                { text: 'agt_ordHRPO', value: 'agt_ordHRPO' , align:'right'},
                { text: 'agt_po', value: 'agt_po' , align:'right'},
                { text: 'sep_ordCRC', value: 'sep_ordCRC' , align:'right'},
                { text: 'sep_ordHRPO', value: 'sep_ordHRPO' , align:'right'},
                { text: 'sep_po', value: 'sep_po' , align:'right'},
                { text: 'okt_ordCRC', value: 'okt_ordCRC' , align:'right'},
                { text: 'okt_ordHRPO', value: 'okt_ordHRPO' , align:'right'},
                { text: 'okt_po', value: 'okt_po' , align:'right'},
                { text: 'nov_ordCRC', value: 'nov_ordCRC' , align:'right'},
                { text: 'nov_ordHRPO', value: 'nov_ordHRPO' , align:'right'},
                { text: 'nov_po', value: 'nov_po' , align:'right'},
                { text: 'des_ordCRC', value: 'des_ordCRC' , align:'right'},
                { text: 'des_ordHRPO', value: 'des_ordHRPO' , align:'right'},
                { text: 'des_po', value: 'des_po' , align:'right'}
            ],
            hrps: [],
            dialog: false,
            thick:'',
            thicks: [],
            width:'',
            widths: [],
            menu: false,
            picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date:'',
            datas: [],
            thick_hrps: [],
            width_qqs: [],
            temps: [],
            data_fix: []
        }
    },
    mounted(){
        this.getPullData()
        // this.getThick()
        // this.getWidth()
        this.$refs.picker.activePicker = 'YEAR'   
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
            this.thick = ''
            this.width = ''
            this.year = ''
        },
        save(date) {
            this.$refs.menu.save(date)

            this.$refs.picker.activePicker = 'YEAR'

            this.menu = false

            this.year = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },
        async getPullData(){
            this.hrps = []
            this.datas = []
            this.temps = []
            this.data_fix = []
            this.$store.dispatch('setOverlay', true)

            const respData = await backendApi.fetchCore(`/v2/sm/hrp?year=${this.year ? this.year : ''}&thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}`, '', false, false, false)

            if (respData.status === 200) {
                this.hrps = respData.data

                for (let index = 0; index < this.hrps.length; index++) {
                    this.datas.push(
                        {
                            "thick": this.hrps[index].thick,
                            "width": this.hrps[index].width,
                            "thick_qq": this.hrps[index].thick_qq,
                            "width_qq": this.hrps[index].width_qq,
                            "wgt_hrc": this.hrps[index].wgt_hrc,
                            "wgt_hrp": this.hrps[index].wgt_hrp,
                            "wgt_hrpo": this.hrps[index].wgt_hrpo,
                            "jan_ordCRC": this.hrps[index].jan_ordCRC,
                            "jan_ordHRPO": this.hrps[index].jan_ordHRPO,
                            "jan_po": this.hrps[index].jan_po,
                            "feb_ordCRC": this.hrps[index].feb_ordCRC,
                            "feb_ordHRPO": this.hrps[index].feb_ordHRPO,
                            "feb_po": this.hrps[index].feb_po,
                            "mar_ordCRC": this.hrps[index].mar_ordCRC,
                            "mar_ordHRPO": this.hrps[index].mar_ordHRPO,
                            "mar_po": this.hrps[index].mar_po,
                            "apr_ordCRC": this.hrps[index].apr_ordCRC,
                            "apr_ordHRPO": this.hrps[index].apr_ordHRPO,
                            "apr_po": this.hrps[index].apr_po,
                            "mei_ordCRC": this.hrps[index].mei_ordCRC,
                            "mei_ordHRPO": this.hrps[index].mei_ordHRPO,
                            "mei_po": this.hrps[index].mei_po,
                            "jun_ordCRC": this.hrps[index].jun_ordCRC,
                            "jun_ordHRPO": this.hrps[index].jun_ordHRPO,
                            "jun_po": this.hrps[index].jun_po,
                            "jul_ordCRC": this.hrps[index].jul_ordCRC,
                            "jul_ordHRPO": this.hrps[index].jul_ordHRPO,
                            "jul_po": this.hrps[index].jul_po,
                            "agt_ordCRC": this.hrps[index].agt_ordCRC,
                            "agt_ordHRPO": this.hrps[index].agt_ordHRPO,
                            "agt_po": this.hrps[index].agt_po,
                            "sep_ordCRC": this.hrps[index].sep_ordCRC,
                            "sep_ordHRPO": this.hrps[index].sep_ordHRPO,
                            "sep_po": this.hrps[index].sep_po,
                            "okt_ordCRC": this.hrps[index].okt_ordCRC,
                            "okt_ordHRPO": this.hrps[index].okt_ordHRPO,
                            "okt_po": this.hrps[index].okt_po,
                            "nov_ordCRC": this.hrps[index].nov_ordCRC,
                            "nov_ordHRPO": this.hrps[index].nov_ordHRPO,
                            "nov_po": this.hrps[index].nov_po,
                            "des_ordCRC": this.hrps[index].des_ordCRC,
                            "des_ordHRPO": this.hrps[index].des_ordHRPO,
                            "des_po": this.hrps[index].des_po
                        }
                    )
                }

                for (let index = 0; index < this.hrps.length; index++) {
                    var sameThickWqq = this.calculateSameThickWidthqq(this.hrps[index].thick, this.hrps[index].width_qq)
                    var sameThickqqWqq = this.calculateSameThickqqWidthqq(this.hrps[index].thick_qq, this.hrps[index].width_qq)

                    this.data_fix.push(
                        {
                            "thick": this.hrps[index].thick,
                            "width": this.hrps[index].width,
                            "thick_qq": this.hrps[index].thick_qq,
                            "width_qq": this.hrps[index].width_qq,
                            "wgt_hrc": this.hrps[index].wgt_hrc,
                            "wgt_hrp": this.hrps[index].wgt_hrp,
                            "wgt_hrpo": this.hrps[index].wgt_hrpo,
                            "jan_ordCRC": this.hrps[index].jan_ordCRC,
                            "jan_ordHRPO": this.hrps[index].jan_ordHRPO,
                            "jan_po": this.hrps[index].jan_po,
                            "feb_ordCRC": this.hrps[index].feb_ordCRC,
                            "feb_ordHRPO": this.hrps[index].feb_ordHRPO,
                            "feb_po": this.hrps[index].feb_po,
                            "mar_ordCRC": this.hrps[index].mar_ordCRC,
                            "mar_ordHRPO": this.hrps[index].mar_ordHRPO,
                            "mar_po": this.hrps[index].mar_po,
                            "apr_ordCRC": this.hrps[index].apr_ordCRC,
                            "apr_ordHRPO": this.hrps[index].apr_ordHRPO,
                            "apr_po": this.hrps[index].apr_po,
                            "mei_ordCRC": this.hrps[index].mei_ordCRC,
                            "mei_ordHRPO": this.hrps[index].mei_ordHRPO,
                            "mei_po": this.hrps[index].mei_po,
                            "jun_ordCRC": this.hrps[index].jun_ordCRC,
                            "jun_ordHRPO": this.hrps[index].jun_ordHRPO,
                            "jun_po": this.hrps[index].jun_po,
                            "jul_ordCRC": this.hrps[index].jul_ordCRC,
                            "jul_ordHRPO": this.hrps[index].jul_ordHRPO,
                            "jul_po": this.hrps[index].jul_po,
                            "agt_ordCRC": this.hrps[index].agt_ordCRC,
                            "agt_ordHRPO": this.hrps[index].agt_ordHRPO,
                            "agt_po": this.hrps[index].agt_po,
                            "sep_ordCRC": this.hrps[index].sep_ordCRC,
                            "sep_ordHRPO": this.hrps[index].sep_ordHRPO,
                            "sep_po": this.hrps[index].sep_po,
                            "okt_ordCRC": this.hrps[index].okt_ordCRC,
                            "okt_ordHRPO": this.hrps[index].okt_ordHRPO,
                            "okt_po": this.hrps[index].okt_po,
                            "nov_ordCRC": this.hrps[index].nov_ordCRC,
                            "nov_ordHRPO": this.hrps[index].nov_ordHRPO,
                            "nov_po": this.hrps[index].nov_po,
                            "des_ordCRC": this.hrps[index].des_ordCRC,
                            "des_ordHRPO": this.hrps[index].des_ordHRPO,
                            "des_po": this.hrps[index].des_po,
                            "same_thick_wqq" : parseInt(sameThickWqq),
                            "same_thickqq_wqq" : parseInt(sameThickqqWqq)
                        }
                    )
                }

                console.log(this.data_fix);

                this.$store.dispatch('setOverlay', false)
            }
        },
        async getThick(){
            const respData = await backendApi.fetchCore(`/api/sr/crc_procurement/getThick`, '', false, false, false)

            if (respData.status === 200) {
                this.thicks = respData.data
            }
        },
        async getWidth(){
            const respData = await backendApi.fetchCore(`/api/sr/crc_procurement/getWidth`, '', false, false, false)

            if (respData.status === 200) {
                this.widths = respData.data
            }
        }, 
        calculateSameThickWidthqq(thick, width_qq){
            var total = this.datas.filter(i => i.thick === thick && i.width_qq === width_qq)
            return total.length
        },
        calculateSameThickqqWidthqq(thick_qq, width_qq){
            var total = this.datas.filter(i => i.thick_qq === thick_qq && i.width_qq === width_qq)
            return total.length
        }
    },
    watch: {
        menu (val) {
            console.log(val);
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
    }
}
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}

.theme--light.v-data-table.v-data-table--fixed-header thead th {
    background: #FFFFFF;
    border: 1px solid rgba(31, 31, 31, 0.12);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
}
</style>